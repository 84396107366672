import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonFeedback, createToastFeedback } from '@boopos/design-system';

import api from 'lib/api';

import { AcceptTermSheetProps } from './AcceptTermSheet.interface';

export const AcceptTermSheetAction = ({
  onSuccess,
  loanId,
  children,
  termSheet,
}: AcceptTermSheetProps) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const expired = termSheet?.expiresAt
    ? Date.parse(`${termSheet.expiresAt}`) < Date.now()
    : false;

  const acceptTermSheet = useCallback(async () => {
    try {
      setLoading(true);
      await api.signTermSheet(loanId);

      await onSuccess(() => {
        setLoading(false);
        setSuccess(true);
      });
    } catch (err) {
      setLoading(false);
      console.error(err);
      createToastFeedback({
        type: 'error',
        title: t('DealTimelineStep:accept_term_sheet_error'),
      });
    }
  }, [loanId, onSuccess, t]);

  if (!loanId) return null;

  return (
    <ButtonFeedback
      onClick={acceptTermSheet}
      isLoading={loading}
      isSuccess={success}
      disabled={loading || expired}
      data-testid="accept-loan-terms"
      className="sidebar-primary"
    >
      {children || t('DealTimelineStep:accept_term_sheet')}
    </ButtonFeedback>
  );
};
