import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Link, useOutlet } from 'react-router-dom';

import { Button } from '@boopos/design-system';

import { PageHeader } from 'ui/PageHeader';

import { DealFlowList } from 'components/DealFlowList';
import { ListScreenLayout } from 'components/ListScreenLayout';
import { PageModalWrapper } from 'components/Modal';
import { QualificationBanner } from 'components/QualificationBanner';
import { AnimatedScreenOpacityWrapper } from 'components/ScreenLayout';

import { routePaths } from 'router/routePaths';

import { useListStatus } from './DealFlowListScreen.hooks';

export const DealFlowListScreen = () => {
  const { t } = useTranslation();
  const outlet = useOutlet();
  const { listStatus, setListStatus } = useListStatus();

  const showActions =
    !listStatus?.isFirstLoading &&
    !listStatus?.isEmpty &&
    !listStatus?.isError &&
    false; // Force hide actions for now

  return (
    <>
      <Helmet>
        <title>{t('DealFlowListScreen:title')}</title>
      </Helmet>
      <AnimatedScreenOpacityWrapper>
        <ListScreenLayout.Wrapper>
          <PageHeader
            title={t('DealFlowListScreen:title')}
            action={
              showActions && (
                <Button as={Link} to={routePaths.dealFlowAddTargetBusiness}>
                  {t('DealFlowListScreen:add_target_business')}
                </Button>
              )
            }
            data-testid="deal-flow-screen-header"
          />
          {showActions && <QualificationBanner multiple />}
          <ListScreenLayout.List>
            <DealFlowList notifyStatus={setListStatus} />
          </ListScreenLayout.List>
        </ListScreenLayout.Wrapper>
        <PageModalWrapper>{outlet}</PageModalWrapper>
      </AnimatedScreenOpacityWrapper>
    </>
  );
};
