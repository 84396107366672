import { useTranslation } from 'react-i18next';
import { Link, generatePath, useParams } from 'react-router-dom';

import { Button } from '@boopos/design-system';

import { routePaths } from 'router/routePaths';

import 'screens/BusinessValuationScreen/BusinessValuationScreen.styles';

import { BusinessFinancingEmptyState } from './BusinessFinancingEmptyState';
import { ScreenLayout } from './BusinessFinancingScreen.styles';
import { useBusinessLoans } from './useBusinessLoans';

export const BusinessFinancingScreen = () => {
  const { t } = useTranslation();
  const { businessId } = useParams();

  const { hasLoans, loanCards } = useBusinessLoans({
    businessId,
  });

  const allowToAskLoans = false;

  if (!hasLoans) {
    return (
      <ScreenLayout.Wrapper data-testid="financing-empty-state">
        <BusinessFinancingEmptyState businessId={businessId as string} />
      </ScreenLayout.Wrapper>
    );
  }

  return (
    <ScreenLayout.Wrapper>
      <ScreenLayout.Actions>
        {allowToAskLoans && (
          <Button
            data-testid="new-loan-button"
            variant="secondary"
            as={Link}
            to={generatePath(routePaths.portfolioRequestLoan, {
              businessId,
            })}
            state={{ backUrl: window.location.pathname }}
          >
            {t('BusinessFinancingScreen:request_loan')}
          </Button>
        )}
      </ScreenLayout.Actions>
      <ScreenLayout.Content>{loanCards}</ScreenLayout.Content>
    </ScreenLayout.Wrapper>
  );
};
