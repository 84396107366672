import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Button } from '@boopos/design-system';

import DealFlowEmptyIllustration from 'assets/illustrations/deal-flow-zero-results2.svg';

import { ListScreenLayoutEmpty } from 'components/ListScreenLayout';

import { routePaths } from 'router/routePaths';

export const DealFlowListEmpty = () => {
  const { t } = useTranslation();

  return (
    <ListScreenLayoutEmpty.Wrapper data-testid="deal-flow-list-empty">
      <img src={DealFlowEmptyIllustration} alt={t('DealFlowListEmpty:title')} />
      <ListScreenLayoutEmpty.Title>
        {t('DealFlowListEmpty:title')}
      </ListScreenLayoutEmpty.Title>
      <ListScreenLayoutEmpty.Subtitle>
        {t('DealFlowListEmpty:subtitle')}
      </ListScreenLayoutEmpty.Subtitle>
      <ListScreenLayoutEmpty.ActionContainer>
        <Button as={Link} to={routePaths.forSale}>
          {t('DealFlowListEmpty:action')}
        </Button>
      </ListScreenLayoutEmpty.ActionContainer>
    </ListScreenLayoutEmpty.Wrapper>
  );
};
